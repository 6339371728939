import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { VerboseErrorInput, Textarea } from 'app/components/form';
import { RISK_LEVELS } from 'app/containers/risk-tolerance-questionnaire/result/score-context/utils/constants';
import './styles.scss';

const RiskLevelBucket = ({ level, index, updateRiskLevelAttribute }) => {
  const [labelLanguage, setLabelLanguage] = useState('en');
  const [descriptionLanguage, setDescriptionLanguage] = useState('en');

  return (
    <div className="risk-level__row">
      <div className="risk-level__range">
        <div className="color-square" style={{ backgroundColor: level.color }} />
        <p className="range-value">
          {level.min} → {level.max}
        </p>
      </div>

      <div className="risk-level__label">
        <div className="language-toggle">
          <button
            type="button"
            className={cn({ active: labelLanguage === 'en' })}
            onClick={() => setLabelLanguage('en')}
          >
            EN
          </button>
          <button
            type="button"
            className={cn({ active: labelLanguage === 'es' })}
            onClick={() => setLabelLanguage('es')}
          >
            ES
          </button>
        </div>
        <VerboseErrorInput
          type="text"
          className="form-control"
          value={level.label?.[labelLanguage] || ''}
          onChange={e => updateRiskLevelAttribute('label', e.target.value, index, labelLanguage)}
        />
      </div>

      <div className="risk-level__description">
        <div className="language-toggle toggle-description">
          <button
            type="button"
            className={cn({ active: descriptionLanguage === 'en' })}
            onClick={() => setDescriptionLanguage('en')}
          >
            EN
          </button>
          <button
            type="button"
            className={cn({ active: descriptionLanguage === 'es' })}
            onClick={() => setDescriptionLanguage('es')}
          >
            ES
          </button>
        </div>
        <Textarea
          rows={4}
          maxLength={500}
          className="form-control"
          value={level.description?.[descriptionLanguage] || ''}
          onChange={e =>
            updateRiskLevelAttribute('description', e.target.value, index, descriptionLanguage)
          }
        />
      </div>
    </div>
  );
};

const RiskLevelsConfiguration = ({ riskLevel, handleChange }) => {
  if (!riskLevel.value && !riskLevel.initialValue) riskLevel.value = RISK_LEVELS;

  const effectiveRiskLevels = !_.isEmpty(riskLevel?.value)
    ? riskLevel.value
    : riskLevel?.initialValue || [];

  const [companyRiskLevels, setCompanyRiskLevels] = useState(effectiveRiskLevels);

  useEffect(() => {
    const riskLevels = JSON.stringify(riskLevel?.value || riskLevel?.initialValue || []);
    setCompanyRiskLevels(JSON.parse(riskLevels));
  }, [JSON.stringify(riskLevel.value)]);

  const updateRiskLevelAttribute = (attribute, value, index, lang) => {
    const updatedRiskLevels = companyRiskLevels.map((level, idx) =>
      idx === index
        ? {
            ...level,
            [attribute]: {
              ...level[attribute],
              [lang]: value
            }
          }
        : level
    );

    setCompanyRiskLevels(updatedRiskLevels);
    handleChange('risk_levels', updatedRiskLevels);
  };

  return (
    <div id="risk-level__configuration">
      {companyRiskLevels.map((level, index) => (
        <RiskLevelBucket
          key={index}
          level={level}
          index={index}
          updateRiskLevelAttribute={updateRiskLevelAttribute}
        />
      ))}
    </div>
  );
};

RiskLevelBucket.propTypes = {
  level: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
    label: PropTypes.object.isRequired,
    description: PropTypes.object.isRequired
  }).isRequired,
  index: PropTypes.number.isRequired,
  updateRiskLevelAttribute: PropTypes.func.isRequired
};

RiskLevelsConfiguration.propTypes = {
  riskLevel: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default RiskLevelsConfiguration;
