import PropTypes from 'prop-types';
import React from 'react';
import '../styles.scss';

const ConfigurationFormSection = ({ children, onSubmit, onCancel }) => (
  <form onSubmit={onSubmit} autoComplete="off">
    <div>{children}</div>
    <div className="form-save-wrapper">
      {onCancel && (
        <button type="button" onClick={onCancel} className="btn btn-secondary btn-small">
          Cancel
        </button>
      )}
      <button type="submit" className="btn btn-primary btn-small">
        Save
      </button>
    </div>
  </form>
);

ConfigurationFormSection.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func
};

ConfigurationFormSection.defaultProps = {
  onCancel: null
};

export default ConfigurationFormSection;
