/* eslint-disable no-underscore-dangle */
import {
  CONNECT_INVITATION_SENT,
  FETCHING,
  FETCHING_FAILURE,
  HOUSEHOLD_LINK,
  HOUSEHOLD_UNLINK,
  INVESTOR_COUNT_NEW,
  INVESTOR_CREATE,
  INVESTOR_DELETE,
  INVESTOR_GET,
  INVESTOR_GET_ACCOUNTS,
  INVESTOR_GET_ACCOUNTS_WITH_HOUSEHOLD,
  INVESTOR_GET_EXCEPTIONS,
  INVESTOR_LIST,
  INVESTOR_NOTE_LIST,
  INVESTOR_SAVE_CHART_IMAGE,
  INVESTOR_SET_BREAKDOWN_CUSTOM_SECURITIES,
  INVESTOR_UPDATE,
  INVESTOR_UPDATE_SELECTED_IDS
} from 'constants/actions';
import moment from 'moment';
import config from '../config';
import DataProvider from './data';
import InvestorElasticProvider from './investor.es';

class InvestorProvider extends DataProvider {
  get es() {
    if (!this._es) this._es = new InvestorElasticProvider({ dispatch: this._dispatch });
    return this._es;
  }

  list(params, query = {}, updateMeta = true) {
    return this.provider
      .get(`${config.apiBase}investors/`, { ...params, ...query })
      .then(({ data, error }) => {
        if (!error) {
          this._params = params;
          this.dispatch(INVESTOR_LIST, {
            data: {
              data: data.results || [],
              meta: { params, query, count: data.count, totalPages: data.total_pages },
              updateMeta
            }
          });
        } else this.dispatch(INVESTOR_LIST, { data: { data: [], meta: {}, updateMeta } });
        return data;
      });
  }

  create(investor, list = false) {
    const data = { ...(investor || this.props.data.data.edit) };
    this.dispatch(FETCHING);

    const promise = this.provider.post(`${config.apiBase}investors/`, data).then(data => {
      if (!data.error) {
        this.dispatch(INVESTOR_CREATE, { data: data.data });
        if (list) this.list(this.params);
      } else this.dispatch(FETCHING_FAILURE);
      return data;
    });

    return promise;
  }

  get(id, params = {}) {
    this.dispatch(FETCHING);
    const promise = this.provider.get(`${config.apiBase}investors/${id}/`, params).then(data => {
      if (!data.error) this.dispatch(INVESTOR_GET, { data: data.data });
      else this.dispatch(FETCHING_FAILURE);
      return data;
    });

    return promise;
  }

  update(investor, list = false) {
    const data = { ...investor };
    if (!data.id) return this.create(investor, list);
    this.dispatch(FETCHING);

    const promise = this.provider.put(`${config.apiBase}investors/${data.id}/`, data).then(data => {
      if (!data.error) {
        this.dispatch(INVESTOR_UPDATE, { data: data.data });
        if (list) this.list(this.params);
      } else this.dispatch(FETCHING_FAILURE);
      return data;
    });

    return promise;
  }

  delete(investorId, list = false) {
    this.dispatch(FETCHING);
    const promise = this.provider.delete(`${config.apiBase}investors/${investorId}/`).then(data => {
      if (!data.error) {
        this.dispatch(INVESTOR_DELETE, { data: investorId });
        if (list) this.list(this.params);
      } else this.dispatch(FETCHING_FAILURE);
      return data;
    });
    return promise;
  }

  getManagers(investorId) {
    return this.provider
      .get(`${config.apiBase}investors/${investorId}/managers/`)
      .then(({ data }) => data);
  }

  mergeInvestors(sourceInvestorId, targetInvestorId) {
    return this.provider.post(`${config.apiBase}investors/merge_investors/`, {
      source_investor_id: sourceInvestorId,
      target_investor_id: targetInvestorId
    });
  }

  countNew() {
    return this.provider
      .get(`${config.apiBase}investors/`, { only_new: true })
      .then(({ data, error }) => {
        if (!error) this.dispatch(INVESTOR_COUNT_NEW, { data: data.count });
      });
  }

  /* Exceptions */
  getExceptions(id) {
    const promise = this.provider
      .get(`${config.apiBase}investors/${id}/exceptions/`)
      .then(response => {
        const data = !response.error ? response.data : [];
        this.dispatch(INVESTOR_GET_EXCEPTIONS, { data, investorId: id });
        return data;
      });

    return promise;
  }

  // TODO: Remove this method
  updateException(id, data) {
    return this.provider.patch(`${config.apiBase}exceptions/${id}/`, data);
  }

  /* Notes */
  listNotes(investor) {
    return this.provider
      .get(`${config.apiBase}investors/${investor.id}/notes/`)
      .then(({ data }) => {
        this.dispatch(INVESTOR_NOTE_LIST, { data: data.results, target: investor.id });
        return data;
      });
  }

  lastNote(investor) {
    return this.provider.get(`${config.apiBase}investors/${investor.id}/notes/last/`);
  }

  addNote(investor, note) {
    return this.provider.post(`${config.apiBase}investors/${investor.id}/notes/`, note).then(() => {
      this.listNotes(investor);
    });
  }

  editNote(investor, id, message) {
    return this.provider
      .put(`${config.apiBase}investors/${investor.id}/notes/${id}/`, { message })
      .then(() => {
        this.listNotes(investor);
      });
  }

  removeNote(investor, id) {
    return this.provider
      .delete(`${config.apiBase}investors/${investor.id}/notes/${id}/`)
      .then(() => {
        this.listNotes(investor);
      });
  }

  /* Accounts */
  getAccounts(id, params = {}, action = INVESTOR_GET_ACCOUNTS) {
    if (params && params.ordering) params = { ...params };
    else params = { ...params, ordering: '-number' };

    this.dispatch(FETCHING);

    const promise = this.provider
      .get(`${config.apiBase}investors/${id}/accounts/`, params)
      .then(data => {
        const accounts = data.data || [];
        this.dispatch(action, { data: accounts });
        return data;
      });

    return promise;
  }

  getAccountsWithHousehold(id) {
    return this.getAccounts(id, { with_household: true }, INVESTOR_GET_ACCOUNTS_WITH_HOUSEHOLD);
  }

  moveAccounts(investorId, accounts) {
    return this.provider.post(`${config.apiBase}investors/${investorId}/move_accounts/`, {
      accounts
    });
  }

  /* Household */
  linkHousehold(investorId, data) {
    this.dispatch(FETCHING);
    return this.provider
      .patch(`${config.apiBase}investors/${investorId}/link_household/`, data)
      .then(response => {
        if (!response.error) this.dispatch(HOUSEHOLD_LINK);
        else this.dispatch(FETCHING_FAILURE);
        return response;
      });
  }

  unlinkHousehold(investorId) {
    this.dispatch(FETCHING);
    return this.provider
      .patch(`${config.apiBase}investors/${investorId}/unlink_household/`)
      .then(response => {
        if (!response.error) this.dispatch(HOUSEHOLD_UNLINK);
        else this.dispatch(FETCHING_FAILURE);
        return response;
      });
  }

  /* Selected Investors */
  updateSelectedId(selectedInvestorIds) {
    this.dispatch(INVESTOR_UPDATE_SELECTED_IDS, { data: { selectedInvestorIds } });
  }

  /* CSV */
  downloadPortfoliosCSV(investor, params = {}) {
    return this.provider
      .requestNoJSON(
        `${config.apiBase}investors/${investor.id}/download_accounts_csv/`,
        null,
        params,
        true
      )
      .then(response => response.blob())
      .then(blob => {
        const objectURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = objectURL;
        link.download = `StratiFi ${investor.full_name} Accounts ${moment().format('ll')}.csv`;
        link.click();

        setTimeout(() => {
          window.URL.revokeObjectURL(objectURL);
        }, 250);
      });
  }

  uploadPortfoliosCSV(investor, files) {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('files', file);
    });
    return this.provider.postFormData(
      `${config.apiBase}investors/${investor.id}/bulk_upload_accounts/`,
      formData
    );
  }

  /* Tolerance */
  addManualRiskScore(investorId, data) {
    return this.provider.post(`${config.apiBase}investors/${investorId}/manual_tolerance_rating/`, {
      data
    });
  }

  sendQuestionnaireMail(investorId, { cc, ...mail }) {
    return this.provider.post(`${config.apiBase}investors/${investorId}/send_questionnaire_mail/`, {
      cc: cc ? cc.split(',') : null,
      ...mail
    });
  }

  /* Account Connect */
  sendConnectInvitation(investorId, { cc, bcc, ...mail }) {
    return this.provider
      .post(`${config.apiBase}investors/${investorId}/send_connect_invitation/`, {
        cc: cc.split(','),
        bcc: bcc ? bcc.split(',') : [],
        ...mail
      })
      .then(data => {
        if (!data.error) this.dispatch(CONNECT_INVITATION_SENT);
        return data;
      });
  }

  /* Reports */
  getReportCoverData(investorId, params) {
    return this.provider
      .get(`${config.apiBase}investors/${investorId}/report/`, params)
      .then(({ data }) => data);
  }

  saveChartImage(data) {
    this.dispatch(INVESTOR_SAVE_CHART_IMAGE, { data });
  }

  /* Other */
  setBreakdownCustomSecurities(id, data) {
    this.dispatch(INVESTOR_SET_BREAKDOWN_CUSTOM_SECURITIES, { id, data });
  }
}

export default InvestorProvider;
